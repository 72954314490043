// .modal-body{
//     padding: 0 !important;
//     background-color: black;
// }

.close {
  color: #fff !important;
  font-size: 2rem !important;
  opacity: 0.4 !important;
}

.btn-danger {
  border-radius: 50em !important;
}

img {
  width: 100%;
  height: 100vh;
  background-size: cover;
  position: absolute;
}

.buttonNew {
  margin-top: 10px;
}

.buttonNew_v2 {
  background-color: #f9a844 !important;
  border-color: #f9a844 !important;
  color: #1b1b1b;
  font-weight: 800;
  margin-top: 3%;
}

.buttonNew_v2:hover {
  color: #1b1b1b !important;
}

.card {
  display: inline !important;
  border: none !important;
}

.box {
  background-color: #e7f6ec;
  text-align: center;
  color: #356d4f;
  font-weight: bold;
  box-sizing: border-box;
  border: 3px solid transparent;
  background-clip: padding-box;
  cursor: pointer;
}

.currencyBox {
  width: 20% !important;
}

.depositBtn {
  background-color: #356d4f !important;
  border-color: #356d4f !important;
  font-size: 1em !important;
  line-height: 1 !important;
  border-radius: 0 !important;
  width: 100% !important;
}

.mainDiv {
  background: white;
  margin: 3%;
  padding: 3%;
}

.backDiv {
  margin: 3%;
  padding: 3%;
  color: #fff;
}

.iconLeft {
  font-size: 1.2rem;
  color: #fff;
}

.warningDiv {
  background: #fffae9;
  margin: 3%;
  padding: 3%;
}

.confimationModal {
  background-color: #fff;
  padding: 1rem !important;
}

.error {
  color: red;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
}

.my-modal {
  max-width: 550px;
}
.ml-10 {
  margin-left: 10px;
}
.text_color_white {
  color: #ffffff;
}
.creditCardContainer {
  max-width: 100%;
  min-height: calc(100vh - 105px);
  // max-width: 626px;
  padding: 21px 30px 31px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 500px;
  flex-direction: column;
}
.deposit-card-modal-body.modal-body {
  width: 100%;
  background: radial-gradient(circle, #2a364e 0%, #21232e 100%);
  background-repeat: no-repeat;
  background-position: top;
  padding-top: 1px;
}
.creditCardPaymentFiled {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.creditCardInputWrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}
.title {
  width: 100%;
  color: #a3a3a3;
  font-size: 15px;
  letter-spacing: 1.35px;
  line-height: 27px;
  margin-bottom: 11px;
}
.inputWrap {
  min-height: 60px;
  width: 100%;
  border-radius: 30px;
  background-color: #1d1d2c;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  caret-color: auto;
}
.inputField {
  width: 100%;
  outline: none;
  border: none;
  background: transparent;
  font-weight: bold;
}
.ANC_Continue_btn {
  text-transform: uppercase;
  min-height: 60px;
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1.35px;
  line-height: 27px;
  text-align: center;
  width: 100%;
  background-color: #0091ff;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.creditCardInputContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.deposit-card-modal-content {
  div.modal-dialog {
    .modal-content {
      background-color: unset !important;
    }
  }
}

.ANC_Continue_btn_disabled {
  background-color: #828282;
  cursor: default;
}

.errorMsg {
  color: #ff0000;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 17px;
  width: 100%;
  text-align: center;
}

.redirect_url_div{
  width: 100%;
    margin: 10px;
}
.redirect_url_style{
  overflow-wrap: break-word;
}

.modal-dialog.modal-90w {
  max-width: 650px !important;
}
.modal-dialog.modal-91w {
  max-width: 670px !important;
}